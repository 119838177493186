import React, { Component } from 'react';
import "../styles/contact.css"

class Contact extends Component {
    render(){
        return (
            <div className="contact-content">
                <div className="contact-title">Let's get in touch...</div>
                

            </div>
        )
    }
}

export default Contact;